document.addEventListener("turbo:load", function() {
  show_errors();
  window.color_array = ["olive", "teal", "purple", "grey", "yellow", "brown", "blue", "violet", "orange", "black"];
  
  $('.main.menu .with-tooltip').popup();

  search.init_global_search();

  dashboard.load_sidebar();
  dropdown.init();
  context_menu.init();
  popup.init();

  //$('.menu .item').tab();
  //$('.ui.checkbox').checkbox();

  // $('.sidebar.menu').hover((function() {
  //   if (!$(".sidebar.menu").hasClass('open')) {
  //     console.log("add focus");
  //     $(".sidebar.menu").addClass('focus');
  //   }
  // }), function() {
  //   if (!$(".sidebar.menu").hasClass('open')) {
  //     console.log("remove focus");
  //     $(".sidebar.menu").removeClass('peak').removeClass('focus');
  //   }
  // });
  
  // $('.item.show.sidebar').hover((function() {
  //   if (!$(".sidebar.menu").hasClass('open')) {
  //     $(".sidebar.menu").removeClass('closed').addClass('peak');
  //   }
  // }), function() {
  //   if (!($(".sidebar.menu").hasClass('open') || $(".sidebar.menu").hasClass('focus'))) {
  //     console.log("no focus");
  //     $(".sidebar.menu").removeClass('peak').addClass('closed');
  //   }
  // });

  const preview = $(".img");
  $("input.file").change(function(event) {
    const input = $(event.currentTarget);
    const file = input[0].files[0];
    const reader = new FileReader();
    reader.onload = function(e) {
      const image_base64 = e.target.result;
      preview.attr("src", image_base64);
    };
    reader.readAsDataURL(file);
  });

  $('.item.show.sidebar').click(function() {
    if ($(".sidebar.menu").hasClass('open')) {
      users.settings("sidebar.navigation.opened", false);
      $(".sidebar.menu").removeClass('open').addClass('closed');
      $(".main.content").removeClass('with-sidebar').addClass('without-sidebar');
      return $(".action.bar").removeClass('with-sidebar').addClass('without-sidebar');
    } else {
      users.settings("sidebar.navigation.opened", true);
      $(".sidebar.menu").removeClass('closed').addClass('open');
      $(".main.content").removeClass('without-sidebar').addClass('with-sidebar');
      return $(".action.bar").removeClass('without-sidebar').addClass('with-sidebar');
    }
  });

  $('.action.bar .show.contextbar').click(function() {
    if ($(".context.bar").hasClass('open')) {
      users.settings("contextbar.opened", false);
      $(".context.bar").removeClass('open').addClass('closed');
      $(".main.content").removeClass('with-contextbar').addClass('without-contextbar');
      return $(".action.bar").removeClass('with-contextbar').addClass('without-contextbar');
    } else {
      users.settings("contextbar.opened", true);
      $(".context.bar").removeClass('closed').addClass('open');
      $(".main.content").removeClass('without-contextbar').addClass('with-contextbar');
      return $(".action.bar").removeClass('without-contextbar').addClass('with-contextbar');
    }
  });

  $('.popup').popup({
    hoverable: true,
    inline: true
  });

  $('.menu .browse').popup({
    inline: true,
    hoverable: true,
    position: 'bottom left',
    delay: {
      show: 0,
      hide: 800
    }
  });
});

var dashboard = {
  message_closable() {
    return $('.message .close').on('click', function() {
      $(this).closest('.message').transition('fade');
    });
  },

  sidebar() {
    if ($('.sidebar.menu .chosen').length) { if (!utils.isElementInView($('.sidebar.menu .chosen'), false)) { $('.sidebar.menu .ui.segment')[0].scrollTop = $('.sidebar.menu .chosen')[0].offsetTop; } }
    return $('.ui.navigation.accordion').accordion({
      onChange() {
        const open_accordions = [];
        for (let accordion of Array.from($('.ui.navigation.accordion .title.active'))) {
          open_accordions.push($(accordion).text());
        }
        return users.settings("sidebar.opened_accordions", open_accordions);
      }
    });
  },

  load_sidebar() {
    return $.ajax({
      url: "/dashboard/sidebar",
      type: "GET",
      data: {
        current_controller_name: $(".sidebar.menu").data("controller-name")
      }
    }).done(function(data) {
      $(".sidebar.menu").html(data);
      dashboard.sidebar();
    }).fail(function() {
    });
  }
};

function show_errors() {
  $('.ui.error.message').transition('shake');
  $('.field_with_errors').each(function() {
    $(this).parents('.field').addClass('error');
    $(this).contents().unwrap();
  });
  $('.message .close').on('click', function() {
    $(this).closest('.message').transition('fade');
  })
}

window.dashboard = dashboard;
window.show_errors = show_errors;
