let Utils = function() {};

Utils.prototype = {
  constructor: Utils,
  isElementInView(element, fullyInView) {
    const pageTop = $(window).scrollTop();
    const pageBottom = pageTop + $(window).height();
    const elementTop = $(element).offset().top;
    const elementBottom = elementTop + $(element).height();
    if (fullyInView === true) {
      return (pageTop < elementTop) && (pageBottom > elementBottom);
    } else {
      return (elementTop <= pageBottom) && (elementBottom >= pageTop);
    }
  },

  gen_id() {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let i = 0;
  
    while (i < 5) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
      i++;
    }
    return text;
  }
};
Utils = new Utils;

window.utils = Utils;
