var popup = {
  init() {
    const loader = '<div style="width: 200px; height: 100px;"><div class="ui active inverted dimmer"><div class="ui text loader">Loading</div></div><p></p></div>';
    const popup_body =  '<div style="width: 500px;" class="ui global_search top attached basic segment"></div>';

    $(".ui.assetcard").popup({
      delay: {
        show: 500
      },
      position: 'bottom left',
      html: loader,
      on: 'hover',
      exclusive: true,
      hoverable: true,
      //variation: 'wide'
      onShow(el) {
        popup = this;
        return $.ajax({
          url: `/assets/${$(el).data("item-id")}/card`
        }).done(function(result) {
          popup.html(`<div style="width: 400px; height: 150px; margin-top:-11px; margin-bottom:-26px; margin-left:-16px; margin-right:-16px;">${result}</div>`);
        }).fail(function() {
          popup.html('error');
        });
      }
    });

    $(".remote.popuplist").popup({
      delay: {
        show: 200
      },
      position: 'bottom center',
      html: '<div style="width: 500px; height: 600px; padding-top:40px;"><div class="ui active inverted dimmer"><div class="ui text loader">Loading</div></div><p></p></div>',
      on: 'hover',
      exclusive: true,
      hoverable: true,
      onShow(el) {
        popup = this;
        return $.ajax({
          url: $(el).data("popup-path")
        }).done(function(result) {
          popup.html(result);
        }).fail(function() {
          popup.html('error');
        });
      }
    });

    $(".main.content .action.bar .index.view.setting.icon").popup({
      delay: {
        show: 200
      },
      position: 'bottom center',
      offset: -85,
      html: '<div style="width: 100px; height: 100px;"><div class="ui active inverted dimmer"><div class="ui text loader">Loading</div></div><p></p></div>',
      on: 'click',
      exclusive: true,
      hoverable: true,
      onShow(el) {
        popup = this;
        return $.ajax({
          url: '/users/settings',
          type: 'GET',
          dataType: 'json',
          complete() {},

          success(data, textStatus) {
            return $.ajax({
              data: {
                items_per_page: data[$(el).data("user-settings")]
              },
              url: '/dashboard/settings'
            }).done(function(result) {
              popup.html(result);
              search.init_settings(el);
            }).fail(function() {
              popup.html('error');
            });
          },
          error(jqXHR, textStatus, errorThrown) {
            return console.log(errorThrown);
          }
        });
      }
    });

    $(".ui.upwcard").popup({
      delay: {
        show: 500
      },
      position: 'bottom left',
      html: loader,
      on: 'hover',
      exclusive: true,
      hoverable: true,
      //variation: 'wide'
      onShow(el) {
        popup = this;
        return $.ajax({
          url: `/user_profile_webs/${$(el).data("item-id")}/card`
        }).done(function(result) {
          popup.html(`<div style="width: 400px; height: 50px; margin-top:-11px; margin-bottom:-26px; margin-left:-16px; margin-right:-16px;">${result}</div>`);
        }).fail(function() {
          popup.html('error');
        });
      }
    });

    $(".ui.contactcard").popup({
      delay: {
        show: 500
      },
      position: 'bottom left',
      html: loader,
      on: 'hover',
      exclusive: true,
      hoverable: true,
      //variation: 'wide'
      onShow(el) {
        popup = this;
        return $.ajax({
          url: `/contacts/${$(el).data("item-id")}/card`
        }).done(function(result) {
          popup.html(`<div style="width: 400px; height: 50px; margin-top:-11px; margin-bottom:-26px; margin-left:-16px; margin-right:-16px;">${result}</div>`);
        }).fail(function() {
          popup.html('error');
        });
      }
    });

    $(".ui.titlecard").popup({
      delay: {
        show: 500
      },
      position: 'bottom left',
      html: loader,
      on: 'hover',
      exclusive: true,
      hoverable: true,
      //variation: 'wide'
      onShow(el) {
        popup = this;
        return $.ajax({
          url: `/titles/${$(el).data("item-id")}/card`
        }).done(function(result) {
          popup.html(`<div style="width: 400px; height: 50px; margin-top:-11px; margin-bottom:-26px; margin-left:-16px; margin-right:-16px;">${result}</div>`);
        }).fail(function() {
          popup.html('error');
        });
      }
    });

    $(".ui.avail-calendar-event").popup({
      delay: {
        show: 500
      },
      position: 'bottom left',
      html: loader,
      on: 'hover',
      exclusive: true,
      hoverable: true,
      target: $(".fc-resource-area"),
      //variation: 'wide'
      onShow(el) {
        popup = this;
        return $.ajax({
          data: {
            avail_id: $(el).data("avid")
          },
          url: '/avails/cards'
        }).done(function(result) {
          popup.html(`<div style="width: 320px; height: 50px; margin-top:-11px; margin-bottom:-26px; margin-left:-16px; margin-right:-16px;">${result}</div>`);
        }).fail(function() {
          popup.html('error');
        });
      }
    });

    $(".ui.delivery-order-calendar-event").popup({
      delay: {
        show: 500
      },
      position: 'bottom left',
      html: loader,
      on: 'hover',
      exclusive: true,
      hoverable: true,
      target: $(".fc-resource-area"),
      //variation: 'wide'
      onShow(el) {
        popup = this;
        return $.ajax({
          data: {
            avail_schedule_id: $(el).data("asid")
          },
          url: '/delivery_orders/card'
        }).done(function(result) {
          popup.html(`<div style="width: 320px; height: 50px; margin-top:-11px; margin-bottom:-26px; margin-left:-16px; margin-right:-16px;">${result}</div>`);
        }).fail(function() {
          popup.html('error');
        });
      }
    });

    $(".main.menu .breadcrumb .history").popup({
      delay: {
        show: 200
      },
      position: 'bottom center',
      html: '<div style="width: 500px; height: 300px;"><div class="ui active inverted dimmer"><div class="ui text loader">Loading</div></div><p></p></div>',
      on: 'click',
      exclusive: true,
      inline: false,
      onShow(el) {
        const item_id = $(el).data("itemId");
        const item_type = $(el).data("itemType");
        popup = this;
        return $.ajax({
          url: `/versions/items/${item_type}/${item_id}`
        }).done(function(result) {
          popup.html(result);
          $('.version').each(function(i, entry) {
            $(entry).unbind('mouseenter mouseleave');
            const changeset = $(entry).data('changeset');
            $(entry).hover((function() {
              version.highlight_diff(changeset, "on");
            }), function() {
              version.highlight_diff(changeset, "off");
            });
            return $(entry).find('.revert').on('click', function() {
              $(entry).unbind('mouseenter mouseleave');
              version.set_diff(changeset);
              $(popup).popup('hide all');
              return false;
            });
          });
        }).fail(function() {
          popup.html('error');
        });
      }
    });

    $(".ui.padded.breadcrumb .navigation").popup({
      delay: {
        show: 100
      },
      position: 'bottom center',
      html: '<div style="width: 300px; height: 60px;"><div class="ui active inverted dimmer"><div class="ui text loader"></div></div><p></p></div>',
      on: 'hover',
      exclusive: true,
      hoverable: true,
      inline: true,
      onShow(el) {
        const controller_name = $(el).data("controller-name");
        const action_name = $(el).data("action-name");
        const record_id = $(el).data("record-id");
        popup = this;
        return $.ajax({
          url: `/breadcrumb/navigation/${controller_name}/${record_id}/${action_name}`
        }).done(function(result) {
          popup.html(result);
        }).fail(function() {
          popup.html('error');
        });
      }
    });

    return $(".ui.metadatatitlecard").popup({
      delay: {
        show: 500
      },
      position: 'bottom left',
      html: loader,
      on: 'hover',
      exclusive: true,
      hoverable: true,
      //variation: 'wide'
      onShow(el) {
        popup = this;
        return $.ajax({
          data: {
            type: ':compact'
          },
          url: `/metadata_titles/${$(el).data("item-id")}/card`
        }).done(function(result) {
          popup.html(`<div style="width: 400px; height: 150px; margin-top:-11px; margin-bottom:-26px; margin-left:-16px; margin-right:-16px;">${result}</div>`);
        }).fail(function() {
          popup.html('error');
        });
      }
    });
  }
};

window.popup = popup;
