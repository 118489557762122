document.addEventListener("turbo:load", function() {
  if ($("#notifications").length > 0) {
    menu_button()
    return
  }
});

function menu_button() {
  $(".main.menu .notifications.item").click(() =>
    $('body').dimmer({
      dimmerName: "quickmenu",
        onShow() {
          $(".ui.dimmer.quickmenu").append("<div id='quickmenu' class='open animated slideInRight'><div class='quickmenu header'></div><div class='quickmenu body'><span class='center'><br/><br/><br/><div class='fa-3x'><i class='far fa-circle-notch fa-spin'></i></div></span></div></div>");
          $("#quickmenu .quickmenu.header").append("<a href='#'><h3 class='ui header'><i class='fas fa-bell fa-fw fa-sm icon' aria-hidden='true'></i>&nbsp;&nbsp;&nbsp;<span>Notifications</span></h3></a>");
          return load_notifications();
        },
        onHide() {
          return $(".ui.dimmer.quickmenu").remove();
        }
    }).dimmer('show')
  )

  function load_notifications() {
    return $.ajax({
      url: "/notifications/quickmenu",
      type: "GET",
      success(data, textStatus, xhr) {
        $("#quickmenu .quickmenu.body").html(data);
        return
      },

      error(jqXHR, textStatus, errorThrown) {
        console.log(errorThrown);
        return $("#quickmenu .quickmenu.body").html("<br/><br/><span class='center'><div class='ui red basic label'>Loading Error</div></span>");
      }
    });
  }
}
