import './user_session'

document.addEventListener("turbo:load", function() {
  on_register_button_click()
  on_password_forgotten_button_click()
  validate_password_forgotten_input()
  if ($("#user_session").length > 0) {
    //load_particles()
    return 
  }

});

function on_register_button_click() {
  $("#user_session").on('click', '.register.button', function(e) {
    $.ajax({
      url: '/users/register',
      type: 'get'
    }).done(function(data) {
      $("body").append(data)
      show_modal('.register.modal');
    }).fail(function(error) {
      console.log(errorThrown);
    })
    event.preventDefault();
  });
}

function validate_request_account_input() {
  const submit_button = $('#request_account_submit')

  $('#create_account .mandatory').on('change keydown paste input', function(e) {
    let filled_out = true
    $('#create_account .mandatory').each(function() {
      if ($.trim($(this).val()) == '') { filled_out = false }
    })
    if(filled_out) {
      submit_button.removeClass('disabled')
      submit_button.removeAttr('disabled')
    } else {
      submit_button.addClass('disabled')
      submit_button.attr('disabled', 'disabled')
    }
  })
}

function on_password_forgotten_button_click() {
  $("#user_session").on('click', '.password.forgotten', function() {
    $.ajax({
      url: '/users/password_forgotten',
      type: 'get'
    }).done(function(data) {
      $("body").append(data)
      show_modal('.password.forgotten.modal');
    }).fail(function(error) {
      console.log(errorThrown);
    })
    event.preventDefault();
  });
}

function validate_password_forgotten_input() {
  const submit_button = $('#reset_password_submit')
  const mandatory_fields = ['#user_password', '#user_password_confirmation']

  $(mandatory_fields.join(',')).on('change keydown paste input', function(e) {
    if (mandatory_fields.filter(field => $(field).val().length == 0).length > 0) {
      submit_button.addClass('disabled');
      submit_button.attr('disabled', 'disabled');
    } else {
      submit_button.removeClass('disabled');
      submit_button.removeAttr('disabled');
    }
  })
}

function show_modal(modal_name) {
  $(modal_name).modal({
    observeChanges: true,
    transition: 'fade up',
    onShow() {
      $(this).find('.ui.dropdown').dropdown()
      if (modal_name === ".password.forgotten.modal") {
        password_forgotten_submit_handler()
        $(this).find('.back.button').click(function() {
          $(modal_name).modal('hide')
        });
      } else if (modal_name === ".register.modal") {
        validate_request_account_input()
      }
    },
    onHidden() {
      $(modal_name).remove();
    }
  }).modal("show");
}

function password_forgotten_submit_handler() {
  let description = $('.password.forgotten form .description')
  $('.password.forgotten form').on('ajax:success', function(event) {
    let [data, status, xhr] = event.detail
    description.addClass('positive').removeClass('negative')
      .find('p').text(xhr.responseText);
    $('#email').addClass('disabled');
    $('.password.forgotten form .reset.button').hide();
    $('.password.forgotten form .back.button').show();
  }).on('ajax:error', function(event) {
    let [data, status, xhr] = event.detail
    description.addClass('negative').removeClass('positive')
      .find('p').text(xhr.responseText);
  });
}

function load_particles() {
  if ($("#particles-js").length > 0) {
    particlesJS('particles-js', {
      'particles': {
        'number': {
          'value': 150,
          'density': {
            'enable': true,
            'value_area': 900
          }
        },
        'color': { 'value': '#ffffff'
      },
        'shape': {
          'type': 'circle',
          'stroke': {
            'width': 0,
            'color': '#000000'
          },
          'polygon': { 'nb_sides': 8
        }
        },
        'size': {
          'value': 3,
          'random': true,
          'anim': {
            'enable': false,
            'speed': 40,
            'size_min': 0.1,
            'sync': false
          }
        },
        'line_linked': {
          'enable': true,
          'distance': 150,
          'color': '#ffffff',
          'opacity': 0.4,
          'width': 1
        },
        'move': {
          'enable': true,
          'speed': 0.8,
          'direction': 'none',
          'random': true,
          'straight': false,
          'out_mode': 'out',
          'bounce': true,
          'attract': {
            'enable': false,
            'rotateX': 600,
            'rotateY': 1200
          }
        }
      },
      'retina_detect': true
    });
  }
}

window.validate_request_account_input = validate_request_account_input;
