
var context_menu = {
  init() {
    context_menu.init_menu();
    return context_menu.init_copy_id_button();
  },

  init_copy_id_button() {
    return $('.copy.id.item').click(function() {
      const copy_value = $(this).data('modelValue');
      $('#copytextarea').text(copy_value);
      $('#copytextarea').show();
      $('#copytextarea').select();
      const icon = $(this).find('i');
      try {
        const successful = document.execCommand('copy');
        if (successful) {
          icon.addClass('green').delay(1000).queue(() => icon.removeClass('green').dequeue());
        } else {
          icon.addClass('red').delay(1000).removeClass('red').queue(() => icon.removeClass('red').dequeue());
        }
      } catch (err) {
        icon.addClass('red').delay(1000).removeClass('red').queue(() => icon.removeClass('red').dequeue());
      }
      return $('#copytextarea').hide();
    });
  },

  init_menu() {
    $('.context.menu').off("click");
    return $('.context.menu').click(function(event) {
      // TODO maybe remove this hack and place the dropdown outside the context menu span
      // to prevent double clicks
      if ($(event.target).parents('.context.menu .vertical.menu').length > 0) {
        return;
      }
      
      const menu = $(this);
      (menu).find('.vertical.menu').html("");
      return $.ajax({
        url: "/context_menu_entry",
        data: {
          model_id: $(this).data('model-id'),
          model_name: $(this).data('model-name'),
          title: $(this).data('title'),
          menu_type: $(this).data('menu-type')
        }
      }).done(function(result) {
        $(menu).find('.vertical.menu').html(result);
        context_menu.init_copy_id_button();
        $(menu).find('.ui.actions.dropdown').dropdown({
          action: 'hide'
        }).dropdown('show');
      }).fail(function() {
        console.log("error");
      });
    });
  }
};

window.context_menu = context_menu;