import './vendor/jquery'

import Rails from "@rails/ujs"
import '@rails/request.js'
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"

import { Application } from "@hotwired/stimulus"
window.Stimulus = Application.start()

import { post } from '@rails/request.js'
window.post = post

import controllers from "./**/*_controller.js"
controllers.forEach((controller) => {
  Stimulus.register(controller.name.replace("controllers--", ""), controller.module.default)
})

Rails.start()
ActiveStorage.start()

import 'fomantic-ui/dist/semantic'
import './vendor/fontawesome'
import './vendor/nested_form_fields.js'
import "flowbite/dist/flowbite.turbo.js";

import "./src/**/*"

import '@carbon/web-components/es/components/button/index.js';
import '@carbon/web-components/es/components/data-table/index.js';
import '@carbon/web-components/es/components/date-picker/index.js';
import '@carbon/web-components/es/components/dropdown/index.js';
import '@carbon/web-components/es/components/form/index.js';
import '@carbon/web-components/es/components/form-group/index.js';
import '@carbon/web-components/es/components/pagination/index.js';
import '@carbon/web-components/es/components/popover/index.js';
import '@carbon/web-components/es/components/select/index.js';
import '@carbon/web-components/es/components/stack/index.js';
import '@carbon/web-components/es/components/textarea/index.js';
import '@carbon/web-components/es/components/text-input/index.js';
import '@carbon/web-components/es/components/toggle/index.js';
import '@carbon/web-components/es/components/ui-shell/index.js';
