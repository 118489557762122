document.addEventListener("turbo:load", function() {
  if ($("#content_catalog").length > 0) {
    search.init("/content_catalogs")
    $(".ui.dropdown").dropdown();

    $(document).keydown(function(event) {
      if (event.keyCode === 13) {
        if ($("#comments input").is(":focus")) {
          $("#comments .bottom.box .button").click()
          return false;
        } else {
          event.preventDefault();
          return false;
        }
      }
    });

    $("#comments .box .button").click(function () {
      App.comments.perform("post", {comment: $("#comments input").val()})
      $("#comments input").val("")
    })
  }
});
