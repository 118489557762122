var forms = {
  enable(queue_entry, form) {
    if (queue_entry == null) { queue_entry = "default"; }
    if (form == null) { form = $('.ui.form'); }
    forms.queue.delete(queue_entry);
    if (forms.queue.size === 0) {
      $('input[type=submit]', form).removeAttr('disabled');
    }
  },
  disable(queue_entry, form) {
    if (form == null) { form = $('.ui.form'); }
    forms.queue.add(queue_entry);
    $('input[type=submit]', form).attr('disabled', 'disabled');
  }
};

forms.queue = new Set();
    
window.forms = forms;
