import './users'

document.addEventListener("turbo:load", function() {
  if ($("#users").length > 0) {
    $("#users .dropdown").dropdown({placeholder: false});
    init_search_filter('/users');
    search.init("/users");
  }
  if ($("#user").length > 0) {
    users.permissions();
    $('.state.fields .checkbox').click(function() {
      if ($('#user_active').is(":checked") && $('#user_approved').is(":checked") && $('#user_confirmed').is(":checked")) {
        $('.send.email.info.message').show()
      } else {
        $('.send.email.info.message').hide()
      }
    })

    $('.add.persona').click(function() {
      console.log(".add.persona")
      
    })

    $("#user .dropdown").dropdown({placeholder: false});
    $('#user .menu .item').tab();
  }
});

function init_search_filter(path) {
  $('.not.active.item').click(function() {
    search.reset_search(path);
    $("#search_active").dropdown('set exactly', ['false']);
    search.perform_search(path);
  });
  $('.not.approved.item').click(function() {
    search.reset_search(path);
    $("#search_approved").dropdown('set exactly', ['false']);
    search.perform_search(path);
  });
  $('.not.confirmed.item').click(function() {
    search.reset_search(path);
    $("#search_confirmed").dropdown('set exactly', ['false']);
    search.perform_search(path);
  }); 
}        

const users= {
  settings(key, value) {
    return $.ajax({
      url: '/users/' + $("#current_user_id").val() + '/settings',
      type: 'POST',
      dataType: 'json',
      data: {
        settings: JSON.stringify({
        key: key,
        value: value
      })},

      complete() {},

      success(data, textStatus) {},

      error(jqXHR, textStatus, errorThrown) {
        return console.log(errorThrown);
      }
    });
  },

  init_index_body() {
    
  },

  permissions() {
    return $(".permission.checkbox").change(function() {
      
      var permissions = "";
     
      $(".permission.checkbox input[value!='none']:checked").each(function() {
        name = $(this).val();
        var authorizable_type = $(this).attr("authorizable_type");
        if (authorizable_type == "") {
          authorizable_type = null;
        } else {
          authorizable_type = '"' + authorizable_type + '"';
        }
        var role = '{"name":"' + name +'","authorizable_type":' + authorizable_type +',"authorizable_id":null}'; 
        permissions = permissions + role + ",";
      });

      permissions = "[" + permissions + "]";
      $("#user_permissions").val(permissions.replace(/,(?=[^,]*$)/, ''));
    });
  }
};

window.users = users;
