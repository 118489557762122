document.addEventListener("turbo:load", function() {
  if ($("#contacts").length > 0) {
    search.init("/contacts")
    $("#contacts .ui.dropdown").dropdown();
  }

  if ($("#contact").length > 0) {
    $("#contact .ui.dropdown").dropdown();

    $('.ui.calendar').calendar({
      type: 'date',
      showDropdowns: true,
      opens: "left",
      firstDayOfWeek: 1,
      formatter: {
        date: function (date, settings) {
        if (!date) return '';
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        return day + '.' + month + '.' + year;
        }
      }
    });

  }

  if ($(".account_type.fields").length > 0) {
    $(".account_type.fields .card").click((function() {
      $(".account_type.fields .card").removeClass("primary").removeClass("highlight")
      
      $(this).addClass("primary").addClass("highlight")
      $(this).find(".radio.checkbox input").prop('checked', "checked")
      
      if ($(this).find(".radio.checkbox input").attr("data-type") == "person") {
        $(".organization").addClass("hidden")
        $(".user").removeClass("hidden")
        $(".departments.person").removeClass("hidden")
        $(".departments.organization").addClass("hidden")
      } else {
        $(".organization").removeClass("hidden")
        $(".user").addClass("hidden")
        $(".departments.organization").removeClass("hidden")
        $(".departments.person").addClass("hidden")
        $(".departments .dropdown").dropdown("clear")
      }
    }));
  }

  menu_button();
});

function menu_button() {
  $(".action.bar .add.contact").click(() =>
    $('body').dimmer({
      dimmerName: "quickmenu",
        onShow() {
          $(".ui.dimmer.quickmenu").append("<div id='quickmenu' class='open animated slideInRight'><div class='quickmenu header'></div><div class='quickmenu body'><span class='center'><br/><br/><br/><div class='fa-3x'><i class='far fa-circle-notch fa-spin'></i></div></span></div></div>");
          $("#quickmenu .quickmenu.header").append("<a href='#'><h3 class='ui header'><i class='fas fa-user fa-fw fa-sm icon' aria-hidden='true'></i>&nbsp;&nbsp;&nbsp;<span>Contact</span></h3></a>");
          return load_body();
        },
        onHide() {
          return $(".ui.dimmer.quickmenu").remove();
        }
    }).dimmer('show')
  )

  function load_body() {
    return $.ajax({
      url: "/contacts/quickmenu",
      type: "GET",
      success(data, textStatus, xhr) {
        $("#quickmenu .quickmenu.body").html(data);
        $("#contact .ui.dropdown").dropdown();
        $("#new_contact .button.submit").click(() => $("#new_contact .segment").prepend('<div class="ui active inverted dimmer submit"><div class="ui text loader"></div></div>'));

        return $(document).ready(() =>
          $("#new_contact").on("ajax:success", (e, data, status, xhr) => $(".ui.dimmer.quickmenu").remove()).on("ajax:error", function(e, xhr, status, error) {
            $("#new_contact .segment .ui.active.dimmer").remove();
            $(".quickmenu.body").prepend(`<div class="ui negative message"><i class="close icon"></i><div class="header">${error}</div><p>${xhr.responseText}</p></div>`);
            return dashboard.message_closable();
          })
        );
      },

      error(jqXHR, textStatus, errorThrown) {
        console.log(errorThrown);
        return $("#quickmenu .quickmenu.body").html("<br/><br/><span class='center'><div class='ui red basic label'>Loading Error</div></span>");
      }
    });
  }

}

const contacts= {
  init_index_body() {
    var selected_item = $("#search_account_type").dropdown("get value")
    $('.vertical.menu.contact .item[data-tab=all]').addClass('active')

    switch (selected_item[0]) {
      case "2":
        $('.vertical.menu.contact .item').removeClass('active');
        $('.vertical.menu .item[data-tab=company]').addClass('active');
        break;
      case "1":
        $('.vertical.menu.contact .item').removeClass('active');
        $('.vertical.menu.contact .item[data-tab=person]').addClass('active');
        break;
    }

    $('.vertical.menu.contact .item').on('click', function() {
      $('.vertical.menu.contact .item').removeClass('active');
      $(this).addClass('active');
      switch ($(this).attr("data-tab")) {
        case "all":
          $("#search_account_type").dropdown("set exactly", [])
          break;
        case "company":
          $("#search_account_type").dropdown("set exactly", ["2"])
          break;
        case "person":
          $("#search_account_type").dropdown("set exactly", ["1"])
          break;
      }
      $(".start-search .primary.button").click();
   }); 
  }
};

window.contacts = contacts;
