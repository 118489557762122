const version = {
  highlight_diff(changeset, hover) {
    return $.each(changeset, function(key, value) {
      if (hover === "on") { 
        $(`[id$=${key}]`).addClass("diff");
        $(`[id$=${key}]`).data('original-value', $(`[id$=${key}]`).val());
        return $(`[id$=${key}]`).val(value[0]);
      } else {
        $(`[id$=${key}]`).removeClass("diff");
        return $(`[id$=${key}]`).val($(`[id$=${key}]`).data('original-value'));
      }
    });
  },
  
  set_diff(changeset) {
    return $.each(changeset, function(key, value) {
      $(`[id$=${key}]`).val(value[0]);
      return $(`[id$=${key}]`).removeClass("diff");
    });
  }
};

window.version = version;
