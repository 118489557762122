//$.fn.dropdown.settings.selectOnKeydown = false
$.fn.dropdown.settings.forceSelection = false;
$.fn.dropdown.settings.saveRemoteData = false;

const dropdown = {
  init() {
    $('.ui.fixed.menu .dropdown').dropdown();
    return $('.ui.dropdown.noselection').dropdown({
      placeholder: false,
      action: "hide"
    });
  },
  
  init_action_menu() {
    return $('.ui.actions.dropdown').dropdown({
      action: 'hide'});
  }
};

const dropdownTemplates = {
  search_menu(response, fields) {
    const values = response[fields.values] || {};
    let html = '';
    $.each(values, function(index, option) {
      html += `<div class="item" data-value="${option[fields.value]}">${option[fields.name]}</div>`;
    });
    return html;
  }
};

window.dropdown = dropdown;
window.dropdownTemplates = dropdownTemplates;
