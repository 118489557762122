import './search'

$.fn.search.settings.maxResults = 1000;

let asset_project_search_result = null;

var search = {
  init(path) {
    const class_name = path.replace("/", "");
    search.add_pagination_to_actionbar(class_name);

    if ($(".search.bar").length > 0) {
      $('.search.accordion').accordion({
        selector: {
          trigger: '.title .icon'
        }
      });

      $('.search.bar .dropdown.search-field').not('.api').not('.custom').dropdown({ fullTextSearch: true });
      $('.search.bar .dropdown.filter').dropdown({ fullTextSearch: true });

      $('.ui.calendar').calendar({
        type: 'date',
        showDropdowns: true,
        opens: "left",
        startDate: $('#search_date_start').val(),
        endDate: $('#search_date_start').val(),
        firstDayOfWeek: 1,
        formatter: {
          date: function (date, settings) {
          if (!date) return '';
          var day = date.getDate();
          var month = date.getMonth() + 1;
          var year = date.getFullYear();
          return day + '.' + month + '.' + year;
          }
        }
      });

      $('.ui.calendar.year').calendar({
        type: 'year',
        showDropdowns: true,
        opens: "left"
      });
      search.perform_search(path)
    }

    // $(document).keydown(function(event) {
    //   if (event.altKey) {
    //     return search.alternative_pageination_buttons("keydown");
    //   }
    // });
    $(document).keyup(function(event) {
      if (event.keyCode === 18) {
        search.alternative_pageination_buttons("keyup");
      }
      if (event.keyCode === 13) {
        if ($("#search_input").is(":focus")) {
          return $(".start-search .primary.button").click();
        }
      }
    });

    if ($("#search_input").length > 0) {
      search.reset_search(path);
      search.set_search_form(class_name);

      $("#search_input").focus();

      $(".start-search button").click(() => search.perform_search(path));

      return $(".search.bar .search-help").click(function() {
        $('.ui.accordion.search.helper').accordion();
        return $('.ui.accordion.search.helper').accordion("toggle", 0);
      });
    }
  },

  init_settings(el) {
    $('.settings .layout .list').click(() => search.perform_settings_click("list", el));

    $('.settings .layout .default').click(() => search.perform_settings_click("default", el));

    $('.settings .layout .cover').click(() => search.perform_settings_click("cover", el));
    
    return $('#items_per_page.dropdown').dropdown({
      onChange(value, text, $selectedItem) {
        return $.when(users.settings($(el).data("user-settings"), value)).done(function() {
          search.perform_search($(el).data("path"));
          });
      }
    });
  },

  init_global_search() {
    const popup_body =  '<div style="width: 500px;" class="ui global_search top attached basic segment"></div>';
    $(".right.menu .main.search").popup({
      delay: {
        show: 200
      },
      position: 'bottom center',
      offset: -90,
      html: popup_body,
      on: 'click',
      exclusive: true,
      hoverable: false,
      setFluidWidth: true,
      onShow(el) {
        popup = this;
        if (!$(".menu .main.search").val()) {
          search.reset_global_search();
        }

        const global_search_config = 
        [
          { model: ".asset_requests", path: "/asset_requests", limit: 3, position: '.assets_requests' },
          { model: ".content_catalogs", path: "/content_catalogs", limit: 3, position: '.content_catalogs' },
          { model: ".contacts", path: "/contacts", limit: 3, position: '.contacts' }
        ];

        return $.ajax({
          data: {
            search_input: $(".menu .main.search").val()
          },
          url: '/search',
          session_id: utils.gen_id()
        }).done(function(result) {
          popup.html(result);
          for (let config of Array.from(global_search_config)) {
            search.global_search(config);
          }
        }).fail(function() {
          popup.html('error');
        });
      }
    });

    $('.right.menu .item .remove.icon').click(function() {
      $(".menu .main.search").val('');
      search.reset_global_search();
      return $(".menu .main.search").blur();
    });

    $(".menu .main.search").focus(function() {
      return $(this).removeClass("closed").addClass("open");
    });
    $(".menu .main.search").blur(function() {
      if (!$(".menu .main.search").val()) {
        $(this).removeClass("open").addClass("closed");
        return $(".right.menu .main.search").popup('remove popup');
      }
    });
    
    if ($(".menu .main.search").length > 0) {
      let delay;
      $(".menu .main.search").keyup(function(event) {
        if (!$(".menu .main.search").val() || search.check_search_inputs(event)) {
          search.reset_global_search();
          return;
        } else {
          delay((function() {
            $(".right.menu .item").find(".search.link.icon").hide();
            $(".right.menu .item").find(".remove.icon").show();
            $(".right.menu .main.search").popup('remove popup');
            $(".right.menu .main.search").popup('show');
            return false;
          }), 1200);
          return false;
        }
      });
      return delay = (function() {
        let timer = 0;
        return function(callback, ms) {
          clearTimeout(timer);
          timer = setTimeout(callback, ms);
        };
      })();
    }
  },

  global_search(config) {
    return $.ajax({
        dataType: "json",
        data: {
          search_input: $(".menu .main.search").val(),
          items_per_page: config["limit"],
          global_search: true,
        },
        url: config["path"]
      }).done(function(data) {
        if (data.results.length === 0) {
          $(config["position"]).append('<div style="text-align: center;"><br><span class="ui basic teal mini label components">No results</span></div>');
          return $(config["position"] + ' .ui.active.loader').remove();
        } else {
          console.log(data.count)
          console.log(data.html)
          $(`.ui.dividing.header${config["model"]} .pull-right`).find(".text").text(data.count);
          return;
        }
      }).fail(function() {
        popup.html('error');
    });
  },

  reset_global_search() {
    $(".right.menu .main.search").popup('remove popup');
    $(".right.menu .item").find(".search.link.icon").show();
    return $(".right.menu .item").find(".remove.icon").hide();
  },

  perform_settings_click(layout, el) {
      $('.settings .layout .icon').removeClass('active');
      $(`.settings .layout .${layout} .icon`).addClass('active');
      if (layout === "default") {
        layout = "";
      } else {
        layout = `_${layout}`;
      }

      return $.when(users.settings("layout.assets", layout)).done(function() {
        search.perform_search($(el).data("path"));
      });
    },

  collect_search() {
    const search_params = {};
    $('.search-field').each(function(index, element) {
      if ($(element).hasClass('dropdown')) {
        element = $(element).children().first();
      }
      let value = $(element).val();
      if ($(element).parent().hasClass('checkbox')) {
        value = $(element).prop("checked").toString();
      }
      const id = $(element).attr("id");
      if ((value !== "") && (value !== "false") && (value !== undefined) && (value !== null) && (value.length !== 0)) {
        if ((typeof value) === "string") {
          return search_params[id] = value.trim();
        } else {
          return search_params[id] = value;
        }
      }
    });
    return search_params;
  },

  reset_search(path) {
    $(".search.filter .with-tooltip").popup({
      inline: true
    });
    return $(".reset-search").click(function() {
      $(".search-field").each(function(i) {
        if ($(this).is(':checkbox')) {
          return $(this).prop("checked", false);
        } else if ($(this).hasClass("dropdown")) {
          return $(this).dropdown('clear');
        } else {
          return $(this).val("");
        }
      });
      return $.when(users.settings(`filter.${path.replace("/", "")}`, search.collect_search())).done(function() {
        search.perform_search(path);
      });
    });
  },

  perform_search(path) {
    $(".start-search button").addClass('loading');
    return $.ajax({
      url: path,
      dataType: "script",
      data: search.collect_search(),
      complete() {},

      success(data, textStatus, xhr) {
        $(".start-search button").removeClass('loading');
        search.table_init();
        return search.set_url_params(path.replace("/", ""));
      },

      error(jqXHR, textStatus, errorThrown) {
        console.log(errorThrown);
        search.show_error(path);
        return search.set_url_params(path.replace("/", ""));
      }
    });
  },

  show_error(path) {
    $(".start-search button").removeClass('loading');
    $(`#${path.replace(/^\//, '')}`).html("<div class=\"ui negative message\"><div class=\"header\">We're sorry, something went wrong.</div><p>Reset all Search Filters or reload the page.</p></div>");
    return $("#search-count").html("0");
  },

  set_url_params(class_name) {
    const search_params = search.collect_search();
    const search_params_keys = Object.keys(search_params);

    
    $(".search.bar .reset-search").addClass("hide");
    if (search_params !== null) {
      if (search_params_keys.length > 0) {
        $(".search.bar .reset-search").removeClass("hide");
        $(".filter .count").text(search_params_keys.length);
      }
    }

    const loc = location.href.split('?');
    const keys = search_params_keys;
    let string = "";
    let first_flag = true;
    for (let key of Array.from(keys)) {
      var url_part;
      if (Array.isArray(search_params[key])) {
        url_part = "";
        const array = search_params[key];
        for (let i = 0; i < array.length; i++) {
          const item = array[i];
          if (i > 0) {
            url_part += "&";
          }
          url_part += `${key}[]=${item}`;
        }
        if (array[0] === "") {
          continue;
        }
      } else {
        url_part = key + "=" + search_params[key];
      }
      if ((search_params[key] === "") || (search_params[key] === "false") || (search_params[key] === undefined) || (search_params[key] === null)) {
        continue;
      }
      if (first_flag) {
        if (url_part !== "") {
          string = string + "?" + url_part;
          first_flag = false;
        }
      } else {
        if (url_part !== "") {
          string = string + "&" + url_part;
        }
      }
    }
    history.pushState(null, null, loc[0] + string);

    return users.settings(`filter.${class_name}`, search_params);
  },

  set_search_form(class_name) {
    let settings;
    if ($(".filter.settings").length > 0) {
      settings = $(".filter.settings").data("settings");
    } else {
      settings = null;
    }
    $('.search-field').each(function(index, element) {
      let param = search.getUrlParameter(this.id);
      if ((param !== undefined) && (param !== "")) {
        if ($(`#${this.id}`).hasClass('dropdown')) {
          return $(element).children().first().val(param.replace(/\+/g, ' '));
        } else if ($(`#${this.id}`).is(':checkbox')) {
          let left;
          param = (left = param === "true") != null ? left : {true : false};
          return $(`#${this.id}`).prop("checked", param);
        } else {
          return $(`#${this.id}`).val(search.urldecode(param).replace(/\+/g, ' ').trim());
        }
      } else {
        if (settings !== null) {
          if ($(element).hasClass('dropdown')) {
            param = settings[$(element).children().first().attr("id")];
            // TODO Set API dropdown here
            $(element).dropdown("set exactly", param);
            return $(element).val(param);
          } else if ($(element).is(':checkbox')) {
            let left1;
            param = settings[$(element).attr("id")];
            param = (left1 = param === "true") != null ? left1 : {true : false};
            return $(element).prop("checked", param);
          } else {
            param = settings[$(element).attr("id")];
            if (param !== undefined) {
              return $(element).val(param.trim());
            }
          }
        }
      }
    });
      
    search.set_url_params(class_name);
  },
  
  urldecode(str) {
    return decodeURIComponent((str + ' ').replace(/\+/g, '%20'));
  },

  table_init() {
    // popup.init();
  },
  
  getUrlParameter(sParam) {
    const sPageURL = window.location.search.substring(1);
    const sURLVariables = sPageURL.split('&');
    let i = 0;
    while (i < sURLVariables.length) {
      const sParameterName = sURLVariables[i].split('=');
      if (sParameterName[0] === sParam) {
        return sParameterName[1];
      }
      i++;
    }
  },

  add_result(name, html, count) {
    if ($("#search-count").length > 0) {
      $(name).html(html);
      $("#search-count").html(count);
      const class_name = name.replace("#", "");
      if (window[class_name] !== undefined) {
        if ((typeof window[class_name].init_index_body) === "function") {
          window[class_name].init_index_body();
        }
      }
    }
    search.add_pagination_to_actionbar();
    return context_menu.init();
  },

  add_pagination_to_actionbar() {
    let set_focus = false;
    if ($(".page.switch").is(":focus")) {
      set_focus = true;
    }
    $(".pagination-element").empty().html($(".pagination.menu"));
    if (set_focus) {
      $(".page.switch").focus();
    }
    search.page_switcher();
    search.page_switcher_size();

    $(".pagination-element .item.next, .pagination-element .item.prev").click(() => search.pagination_loader());

    return $('.total-count.with-tooltip').popup({
      position: 'bottom center',
      delay: {
        show: 800,
        hide: 300
      }
    });
  },

  page_switcher() {
    return $(".page.switch").keyup(function(event) {
      search.page_switcher_size();
      if(event.keyCode === 13) {
        let page_number = $(".page.switch").val();
        const total_pages = $(".page.switch").data("total-pages");
        if (page_number > total_pages) {
          page_number = total_pages;
        }
        let direction = "next";
        if ($(".item.next.page").length === 0) {
          direction = "prev";
        }
        $(`.item.${direction}.page`).attr("href", $(`.item.${direction}.page`).attr("href").replace(/(\?|&)page=\d+/, `$1page=${page_number}`));
        Turbolinks.visit($(`.item.${direction}.page`).attr("href"));
        return search.pagination_loader();
      }
    });
  },

  page_switcher_size() {
   if ($(".pagination.menu").length > 0) {
    if ($(".page.switch").length > 0) {
      $(".pagination-element.pull-right").prev().show();
      const size = $(".page.switch").val().length;
      return $(".page.switch").css("width", 15 + (8 * (size - 1)));
    } else {
      return $(".pagination-element.pull-right").prev().hide();
    }
  }
 },
  
  alternative_pageination_buttons(action) {
    if ($(".page.switch").length > 0) {
      if (action === "keydown") {
        $(".pagination-element .first.page").removeClass("hide");
        $(".pagination-element .last.page").removeClass("hide");
        $(".pagination-element .next.page").addClass("hide");
        $(".pagination-element .prev.page").addClass("hide");
      }
      if (action === "keyup") {
        $(".pagination-element .first.page").addClass("hide");
        $(".pagination-element .last.page").addClass("hide");
        $(".pagination-element .next.page").removeClass("hide");
        return $(".pagination-element .prev.page").removeClass("hide");
      }
    }
  },
  
  pagination_loader() {
    return $(".pagination-element .page.item.active.current").addClass("ui active loader");
  },

  check_search_inputs(event) {
    if (event.keyCode === 16) {
      return true;
    } else if (event.keyCode === 17) {
      return true;
    } else if (event.keyCode === 18) {
      return true;
    } else if (event.keyCode === 91) {
      return true;
    } else if (event.keyCode === 93) {
      return true;
    } else if (event.keyCode === 37) {
      return true;
    } else if (event.keyCode === 38) {
      return true;
    } else if (event.keyCode === 39) {
      return true;
    } else if (event.keyCode === 40) {
      return true;
    } else {
      return false;
    }
  },

  add_params_to_url(url, params) {
    let u = url;
    for (let k in params) {
      const v = params[k];
      u += `&${k}=${v}`;
    }
    return u;
  }
};

window.search = search;
