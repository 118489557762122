$(document).on('turbolinks:load', () => api.init());

var api = {
  init() {
    return $('.api.dropdown').dropdown({
      apiSettings: {
        cache: false,
        beforeSend(settings) {
          const value = $(this).data('value');
          const name = $(this).data('name');
          if ($(this).data('params')) {
            settings.onResponse = function(JsonResponse) {
              const response = {
                success: true,
                results: []
              };
              $.each(JsonResponse.results, function(index, item) {
                if (typeof item === 'object') { 
                  response.results.push({ value: `${item.value}`, name: item.name }); 
                }
              });
              return response;
            };
            $.extend(settings.urlData, $(this).data('params'));
          }
          return settings;
        }
      }
    });
  }
};

window.api = api;

$.fn.api.settings.api = {
  'get organizations': '/contacts.json?q={query}&search_input={query}&contactable_type=Organization'
};
